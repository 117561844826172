import * as React from 'react';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { userSelector } from '../../state';
import { useSelector } from 'react-redux';
import { InfoCard } from '../molecules';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';


export const ApiKey: FC = () => {
    const {t} = useTranslation();
    const toast = useRef<Toast>(null);

    let user = useSelector(userSelector);


    function copyApiKeyToClipboard() {
        navigator.clipboard.writeText('' + user?.singleItem?.apiKey);
        toast.current?.show({ severity: 'success', summary: t('screens.apiKey.copiedToClipboard')});
    }

    return (
        <div>
            <Toast ref={toast} />
            <InfoCard icon={<span className="pi pi-key"/>} title={t('screens.apiKey.title')} description={t('screens.apiKey.description')}/>

            <div className="grid mt-4">
                <div className="col-2">
                    {t('screens.apiKey.currentApiKey')}:
                </div>
                <div className="col-10">
                    {user?.singleItem?.apiKey} <i className="pi pi-copy text-500" onClick={event => {copyApiKeyToClipboard();}}></i>
                </div>
                <div className="col-12 mt-6">
                    <Button label={t('screens.apiKey.showApiDocumentation')} link onClick={() =>  window.location.replace('/api/public/swagger-ui')} style={{padding: 0}}/>

                </div>
            </div>


        </div>
    );
}
